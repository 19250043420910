<template>
  <div class="sub-cont">
    <div class="request-tapmenu-wrap">
      <ul style="cursor: pointer">
        <li v-if="isEmployer">
          <router-link
            :class="{ on: routeName === 'JobDocumentList' }"
            to="/request"
          >
            공고별
          </router-link>
        </li>
        <li>
          <router-link
            :class="{ on: routeName === 'requestList' }"
            to="/requestList"
          >
            근무요청
          </router-link>
        </li>
        <li>
          <router-link
            :class="{ on: routeName === 'attendanceList' }"
            to="/attendance"
          >
            출 &#183; 퇴근
          </router-link>
        </li>
        <li>
          <router-link
            :class="{ on: routeName === 'mypageCalcHistory' }"
            to="/mypage/calc-history"
          >
            정산
          </router-link>
        </li>
      </ul>
    </div>
    <div class="myp-buy-wrap">
      <div class="search-item-wrap">
        <div class="label">근무요청일자</div>
        <div class="date-item-wrap">
          <input
            type="text"
            class="inp datepicker"
            id="reqStartDt"
            :value="reqStartDt"
            inputmode="none"
          />
          <span class="e01">-</span>
          <input
            type="text"
            class="inp datepicker"
            id="reqEndDt"
            :value="reqEndDt"
            inputmode="none"
          />
        </div>
        <div class="label">근무일시</div>
        <div class="date-item-wrap">
          <input
            type="text"
            class="inp datepicker"
            id="workStartDt"
            inputmode="none"
            :value="workStartDt"
            @click="workDate"
          />
          <span class="e01">-</span>
          <input
            type="text"
            class="inp datepicker"
            inputmode="none"
            id="workEndDt"
            :value="workEndDt"
          />
        </div>
      </div>
      <div class="opt-item-wrap-con">
        <div class="opt-item-wrap">
          <select v-model="pushStatus" class="select">
            <option hidden>전체</option>
            <option
              v-for="pushSt in PUSH_STATUS"
              :key="pushSt.value"
              :value="pushSt.value"
            >
              {{ pushSt.name }}
            </option>
          </select>
          <input
            v-model="schKeyword"
            type="text"
            class="inp"
            placeholder="근무지, 직종 검색"
          />
        </div>
        <button type="button" @click="onGoPage(1)">검색</button>
      </div>
      <table class="tbl01">
        <tr>
          <th>근무요청일자</th>
          <th id="workdt">근무일자</th>
          <th>{{ `${isEmployee ? '업체명' : '이름'}` }}</th>
          <th class="mobileNone">직종</th>
          <th class="mobileNone">급여</th>
          <th>상태</th>
        </tr>

        <!-- 구직회원 -->
        <template v-if="isEmployee">
          <tr
            v-for="reqItem in requestList"
            :key="reqItem.jobPushId"
            @click="$router.push(`/request/${reqItem.jobPushId}`)"
          >
            <!--근무요청일자-->
            <td>{{ reqItem.workRequestDt }}</td>
            <!-- PC - 근무일자 -->
            <td class="mobileNone">
              {{ reqItem.workStartDate + ' ~ ' + reqItem.workEndDate }}
            </td>
            <!--App - 근무일자-->
            <td class="pcNone">
              {{
                `${workDate(reqItem.workStartDate)}` +
                ' ~ ' +
                `${workDate(reqItem.workEndDate)}`
              }}
            </td>
            <!--이름-->
            <td>{{ reqItem.employer }}</td>
            <!--직종-->
            <td class="mobileNone">
              {{ reqItem.jobPositionName + '/' + reqItem.jobPositionDtlName }}
            </td>
            <!--급여-->
            <td class="mobileNone">
              {{ `${insertCommaToNumber(reqItem.payAmt)}원` }}
            </td>
            <td>
              <div
                class="status"
                :class="{
                  wait: reqItem.pushStatus === PUSH_STATUS[1].value,
                  answ: reqItem.pushStatus === PUSH_STATUS[2].value,
                  deci: reqItem.pushStatus === PUSH_STATUS[3].value,
                  succ: reqItem.pushStatus === PUSH_STATUS[4].value,
                  hold: reqItem.pushStatus === PUSH_STATUS[5].value,
                }"
              >
                {{ PUSH_STATUS_NAME[reqItem.pushStatus] }}
              </div>
            </td>
          </tr>
        </template>
        <!-- 구인회원 -->
        <template v-else>
          <tr
            v-for="reqItem in requestList"
            :key="reqItem.jobPushId"
            @click="$router.push(`/request/${reqItem.jobPushId}`)"
            style="cursor: pointer"
          >
            <td>{{ reqItem.workRequestDt }}</td>
            <td class="pcNone">
              {{
                `${workDate(reqItem.workStartDate)}` +
                ' ~ ' +
                `${workDate(reqItem.workEndDate)}`
              }}
            </td>
            <td class="mobileNone">
              {{ reqItem.workStartDate + ' ~ ' + reqItem.workEndDate }}
            </td>
            <td>{{ reqItem.seeker }}</td>
            <td class="mobileNone">
              {{ reqItem.jobPositionName + '/' + reqItem.jobPositionDtlName }}
            </td>
            <td class="mobileNone">
              {{ `${insertCommaToNumber(reqItem.payAmt)}원` }}
            </td>
            <td>
              <div
                class="status"
                :class="{
                  wait: reqItem.pushStatus === PUSH_STATUS[1].value,
                  hold: reqItem.pushStatus === PUSH_STATUS[5].value,
                  succ: reqItem.pushStatus === PUSH_STATUS[4].value,
                  answ: reqItem.pushStatus === PUSH_STATUS[2].value,
                  deci: reqItem.pushStatus === PUSH_STATUS[3].value,
                }"
              >
                {{ PUSH_STATUS_NAME[reqItem.pushStatus] }}
              </div>
            </td>
          </tr>
        </template>
        <tr>
          <td class="mobileNone" colspan="6" v-if="requestList.length == 0">
            근무요청 내역이 존재하지 않습니다.
          </td>
          <td class="pcNone" colspan="4" v-if="requestList.length == 0">
            근무요청 내역이 존재하지 않습니다.
          </td>
        </tr>
      </table>
      <!-- 페이징 추가 -->
      <Pagination
        :totalElements="totalElements"
        :currentPageIndex="currentPageIndex"
        :listRowCount="listRowCount"
        :pageLinkCount="pageLinkCount"
        @goPage="onGoPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import {
  getRequestListForEmployer,
  getRequestListForEmployee,
} from 'Api/modules'
import { PUSH_STATUS, PUSH_STATUS_NAME } from 'Configs/pushStatus'
import datePickerMixin from 'Mixins/datePicker'
import moment from 'moment'
import Pagination from 'Common/Pagination'
export default {
  components: {
    Pagination,
  },
  mixins: [datePickerMixin],
  data() {
    return {
      PUSH_STATUS: Object.freeze(PUSH_STATUS),
      PUSH_STATUS_NAME: Object.freeze(PUSH_STATUS_NAME),

      reqStartDt: '',
      reqEndDt: '',
      workStartDt: '',
      workEndDt: '',
      pushStatus: PUSH_STATUS[0].value,
      schKeyword: '',

      requestList: [], //근무요청 목록
      // paging
      pageNumber: 0,
      totalElements: 0,
      listRowCount: 10,
      pageLinkCount: 5,
      currentPageIndex: 1,
      isVisibleLeftTab: true,
    }
  },
  watch: {
    reqStartDt(newVal) {
      if (newVal) {
        //endDate가 이미 세팅 되있는 경우
        if (this.reqEndDt) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (this.diffCalendar(newVal, this.reqEndDt) - 1 < 0) {
            this.setDatePickerDate('reqEndDt', newVal, this.setEndDate)
            this.reqEndDt = newVal
          }
        }
      }
    },
    reqEndDt(newVal) {
      if (newVal) {
        //endDate가 이미 세팅 되있는 경우
        if (this.reqStartDt) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (this.diffCalendar(this.reqStartDt, newVal) - 1 < 0) {
            this.setDatePickerDate('reqStartDt', newVal, this.setStartDate)
            this.reqStartDt = newVal
          }
        }
      }
    },
    workStartDt(newVal) {
      if (newVal) {
        //endDate가 이미 세팅 되있는 경우
        if (this.workEndDt) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (this.diffCalendar(newVal, this.workEndDt) - 1 < 0) {
            this.setDatePickerDate('workEndDt', newVal, this.setWorkEndDate)
            this.workEndDt = newVal
          }
        }
      }
    },
    workEndDt(newVal) {
      if (newVal) {
        //endDate가 이미 세팅 되있는 경우
        if (this.workStartDt) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (this.diffCalendar(this.workStartDt, newVal) - 1 < 0) {
            this.setDatePickerDate('workStartDt', newVal, this.setWorkStartDate)
            this.workStartDt = newVal
          }
        }
      }
    },
    '$route.query': {
      deep: true,
      handler() {
        this.setFromQueryParam()
        this.search(false)
      },
    },
  },
  methods: {
    switchLeftTab() {
      this.isVisibleLeftTab = true
    },
    switchRightTab() {
      this.isVisibleLeftTab = false
    },
    onGoPage(curPage) {
      this.currentPageIndex = curPage
      this.pageNumber = curPage - 1 //pageNumber 는 현재페이지에서 -1
      this.search()
    },
    setStartDate(selectedDate) {
      this.reqStartDt = selectedDate
    },
    setEndDate(selectedDate) {
      this.reqEndDt = selectedDate
    },
    setWorkStartDate(selectedWorkDate) {
      this.workStartDt = selectedWorkDate
    },
    setWorkEndDate(selectedWorkDate) {
      this.workEndDt = selectedWorkDate
    },
    async search(setQuery = true) {
      if (!this.reqStartDt || !this.reqEndDt) {
        return false
      }
      //근무요청 목록 조회
      if (this.isEmployee) {
        const res = await getRequestListForEmployee({
          reqStartDt: this.reqStartDt,
          reqEndDt: this.reqEndDt,
          workStartDt: this.workStartDt,
          workEndDt: this.workEndDt,
          pushStatus: this.pushStatus,
          schKeyword: this.schKeyword,
          seekerId: this.account.userId,
          pageNumber: this.pageNumber,
        })
        this.requestList = res.content
        this.totalElements = res.totalElements
        this.currentPageIndex = res.pageable.pageNumber + 1
        this.pageNumber = res.pageable.pageNumber
      } else {
        const res = await getRequestListForEmployer({
          reqStartDt: this.reqStartDt,
          reqEndDt: this.reqEndDt,
          workStartDt: this.workStartDt,
          workEndDt: this.workEndDt,
          pushStatus: this.pushStatus,
          schKeyword: this.schKeyword,
          employerId: this.account.userId,
          pageNumber: this.pageNumber,
        })
        console.log(res)
        this.requestList = res.content
        this.totalElements = res.totalElements
        this.currentPageIndex = res.pageable.pageNumber + 1
        this.pageNumber = res.pageable.pageNumber
      }
      if (setQuery) {
        const query = {
          reqStartDt: this.reqStartDt,
          reqEndDt: this.reqEndDt,
          workStartDt: this.workStartDt,
          workEndDt: this.workEndDt,
          pushStatus: this.pushStatus,
          schKeyword: this.schKeyword,
          pageNumber: this.pageNumber,
        }

        this.$router.push({
          name: 'requestList',
          query,
        })
      }
    },

    setFromQueryParam() {
      const {
        reqStartDt,
        reqEndDt,
        workStartDt,
        workEndDt,
        pushStatus,
        schKeyword,
        pageNumber,
      } = this.$route.query

      this.reqStartDt = reqStartDt
      this.setDatePickerDate('reqStartDt', reqStartDt, this.setStartDate)
      this.reqEndDt = reqEndDt
      this.setDatePickerDate('reqEndDt', reqEndDt, this.setEndDate)
      this.workStartDt = workStartDt
      this.setDatePickerDate('workStartDt', workStartDt, this.setWorkStartDate)
      this.workEndDt = workEndDt
      this.setDatePickerDate('workEndDt', workEndDt, this.setWorkEndDate)
      this.pushStatus = pushStatus
      this.schKeyword = schKeyword
      this.pageNumber = pageNumber

      if (!this.reqStartDt && !this.reqEndDt) {
        const today = moment().format('YYYY-MM-DD')
        const weekday = moment().subtract(7, 'days').format('YYYY-MM-DD')
        this.setDatePickerDate('reqStartDt', weekday, this.setStartDate)
        this.reqStartDt = weekday
        this.setDatePickerDate('reqEndDt', today, this.setEndDate)
        this.reqEndDt = today
        //console.log(weekday)
      }
      if (!this.workStartDt && !this.workEndDt) {
        const today = moment().format('YYYY-MM-DD')
        const momentday = moment().add(1, 'months').format('YYYY-MM-DD')
        this.setDatePickerDate('workStartDt', today, this.setWorkStartDate)
        this.workStartDt = today
        this.setDatePickerDate('workEndDt', momentday, this.setWorkEndDate)
        this.workEndDt = momentday
        //console.log(momentday)
      }
    },
    workDate(str) {
      return String(str).substr(5)
    },
  },
  async created() {
    this.initDatePicker('reqStartDt', this.setStartDate)
    this.initDatePicker('reqEndDt', this.setEndDate)
    this.initDatePicker('workStartDt', this.setWorkStartDate)
    this.initDatePicker('workEndDt', this.setWorkEndDate)

    this.setFromQueryParam()

    await this.search(false)
  },
}
</script>

<style lang="scss" scoped>
.tbl01 {
  tr > td:nth-child(2) {
    cursor: pointer;
  }
}
</style>
